import {
  ComponentAccordion,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageToolkit,
} from '@backstage/plugin-home';
import { Content, InfoCard, Page } from '@backstage/core-components';
import { MockStarredEntitiesApi } from '@backstage/plugin-catalog-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import LogoFullDark from '../Root/LogoFullDark';
import WebIcon from '@material-ui/icons/Web';
import TimelineIcon from '@material-ui/icons/Timeline';
import DnsIcon from '@material-ui/icons/Dns';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PeopleIcon from '@material-ui/icons/People';

import SsidChartIcon from '@mui/icons-material/SsidChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DoneIcon from '@mui/icons-material/Done';
import BrushIcon from '@mui/icons-material/Brush';
import PolylineIcon from '@mui/icons-material/Polyline';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CopyrightIcon from '@mui/icons-material/Copyright';
import RadarIcon from '@mui/icons-material/Radar';

const starredEntitiesApi = new MockStarredEntitiesApi();
starredEntitiesApi.toggleStarred('component:default/example-starred-entity');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-2');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-3');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-4');

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();
  const ExpandedComponentAccordion = (props: any) => (
    <ComponentAccordion expanded {...props} />
  );

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoFullDark />}
            />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoCard title="Resources" noPadding>
                  <Grid item>
                    <HomePageToolkit
                      title="Tools"
                      tools={[
                        {
                          url: 'https://stats.dbp01-apps.cluster.az-we.dglecom.net/?orgId=1',
                          label: 'Grafana',
                          icon: <TimelineIcon />,
                        },
                        {
                          url: 'https://dev.azure.com/dglecom/',
                          label: 'Azure DevOps',
                          icon: <DnsIcon />,
                        },
                        {
                          url: 'https://dashboard.dbp01-apps.cluster.az-we.dglecom.net/',
                          label: 'Kubernetes DBP01 Dashboard',
                          icon: <DnsIcon />,
                        },
                        {
                          url: 'https://dashboard.dbp02-apps.cluster.az-we.dglecom.net/',
                          label: 'Kubernetes DBP02 Dashboard',
                          icon: <DnsIcon />,
                        },
                        {
                          url: 'https://douglas-douglas.instana.io/',
                          label: 'Instana',
                          icon: <SsidChartIcon />,
                        },
                        {
                          url: 'https://emea01.cluster.observability.cloud.sap/e/ccv2-cust-cywsi79vjh-parfumeri1-p1',
                          label: 'Dynatrace',
                          icon: <AutoGraphIcon />,
                        },
                        {
                          url: 'https://logs.dglecom.net/',
                          label: 'Graylog',
                          icon: <ReceiptIcon />,
                        },
                        {
                          url: 'https://gen-docs.svc.az-we.dglecom.net/',
                          label: 'Host the Docs',
                          icon: <FilePresentIcon />,
                        },
                        {
                          url: 'https://system-links-overview.svc.az-we.dglecom.net/',
                          label: 'System Links (Hybris 4)',
                          icon: <AttachFileIcon />,
                        },
                        {
                          url: 'https://azure-devops-dashboard.svc.az-we.dglecom.net/',
                          label: 'Azure Devops Pending Approvals',
                          icon: <DoneIcon />,
                        },
                        {
                          url: 'https://kroki.pub.dglecom.net/',
                          label: 'Kroki Diagrams',
                          icon: <BrushIcon />,
                        },
                        {
                          url: 'https://app.diagrams.net/',
                          label: 'Draw IO',
                          icon: <PolylineIcon />,
                        },
                        {
                          url: 'https://excalidraw.com/',
                          label: 'Excalidraw',
                          icon: <ModeEditIcon />,
                        },
                        {
                          url: 'https://portal.commerce.ondemand.com/subscription/a20d1040758f4798924d3187368d3283/',
                          label: 'Commerce Cloud',
                          icon: <CopyrightIcon />,
                        },
                        {
                          url: 'https://radar.douglas.group/',
                          label: 'Douglas Tech Radar',
                          icon: <RadarIcon />,
                        }
                      ]}
                      Renderer={ExpandedComponentAccordion}
                    />
                    <HomePageToolkit
                      title="Assistance"
                      tools={[
                        {
                          url: 'https://jira.douglas.group/servicedesk/customer/portal/22',
                          label: 'Service Request',
                          icon: <ContactSupportIcon />,
                        },
                      ]}
                      Renderer={ComponentAccordion}
                    />
                    <HomePageToolkit
                      title="Douglas Internal"
                      tools={[
                        {
                          url: 'http://hier.douglas-informatik.de',
                          label: 'Douglas HIER',
                          icon: <WebIcon />,
                        },
                        {
                          url: 'https://atossess.baseservice.net/SES/ess',
                          label: 'ATOSS',
                          icon: <PeopleIcon />,
                        },
                        {
                          url: 'https://douglas.myetweb.com/',
                          label: 'EMPOWER',
                          icon: <WebIcon />,
                        },
                      ]}
                      Renderer={ComponentAccordion}
                    />
                  </Grid>
                </InfoCard>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
